//
//
//

$(window).on('load',function() {
    if(respResize.getWidth() <= respResize.brSm) return;
    var tb = $("#header .banner");
    if (!tb.length) return;
    var md5 = tb.find('a').data('checksum');
    if (!md5 || !md5.length) return;
    var was_displayed = $.cookie(md5);
    if (was_displayed) return;
    
    tb.slideDown(topFixed.updateTop,function(){
        $.event.trigger({type:'topBannerShown'});
    });
    tb.find('.close-banner').on('click', function(){
        var expire_date = new Date();
        expire_date.setTime(expire_date.getTime() + ( 24* 60 * 60000));
        $.cookie(md5, 1, {expires:expire_date, path: '/'});
        tb.slideUp(topFixed.updateTop,function(){
            $.event.trigger({type:'topBannerHide'});
        });
    });
    
});
