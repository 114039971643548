/*
*
*/
 
var scrollController = null;

function highdpiInit()
{
	if($('.replace-2x').css('font-size') == "1px")
	{		
		var els = $("img.replace-2x").get();
		for(var i = 0; i < els.length; i++)
		{
			var src = els[i].src,
                extension = src.substr( (src.lastIndexOf('.') +1) );
			src = src.replace("." + extension, "2x." + extension);
			
			var img = new Image();
			img.src = src;
			if(img.height !== 0)  els[i].src = src; 
		}
	}
}


function bindGrid()
{
	var view = $.totalStorage('display');

	if (view && view != 'grid')
		display(view);
	else
		$('.display').find('li#grid').addClass('selected');
	
	$(document).on('click', '#grid', function(e){
		e.preventDefault();
		display('grid');
	});

	$(document).on('click', '#list', function(e){
		e.preventDefault();
		display('list');
	});
}

function display(view)
{   
    var html;
	if (view == 'list')
	{
		$('ul.product_list.active').removeClass('grid').addClass('list row');
		$('.product_list.active > li').removeClass('product-tile').addClass('col-xs-12');
		$('.product_list.active > li').each(function(index, element) {
			html = '';
			html += '<div class="product-container"><div class="row">';
            html += '<div class="left-block col-xs-4 col-xs-5 col-md-4">' + $(element).find('.left-block').html() + '</div>';
            html += '<div class="center-block col-xs-4 col-xs-7 col-md-4">';
					html += '<h3 itemprop="name">'+ $(element).find('h3').html() + '</h3>';
					var rating = $(element).find('.comments_note').html(); // check : rating
					if (rating !== null) { 
						html += '<div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating" class="comments_note">'+ rating + '</div>';
					}
                    brand = $(element).find('.brand');
                    if (brand.length)
                        html += '<p class="brand" itemprop="brand">'+ brand.html() + '</p>';
                   
					html += '<p class="product-desc">'+ $(element).find('.product-desc').html() + '</p>';
                    html += '<div class="product_link_more">'+ $(element).find('.product_link_more').html() + '</div>';
					var colorList = $(element).find('.color-list-container').html();
					if (colorList !== null) {
						html += '<div class="color-list-container">'+ colorList +'</div>';
					}
					var availability = $(element).find('.availability').html();	// check : catalog mode is enabled
					if (availability !== null) {
						html += '<span class="availability">'+ availability +'</span>';
					}
				html += '</div>';	
				html += '<div class="right-block col-xs-4 col-xs-12 col-md-4"><div class="right-block-content row">';
					var price = $(element).find('.content_price').html();       // check : catalog mode is enabled
					if (price !== null) { 
						html += '<div class="content_price col-xs-5 col-md-12">'+ price + '</div>';
					}
					html += '<div class="button-container col-xs-7 col-md-12">'+ $(element).find('.button-container').html() +'</div>';
            html += '<div class="functional-buttons clearfix col-sm-12">' + $(element).find('.functional-buttons').html() + '</div>';
				html += '</div>';
			html += '</div></div></div>';
		$(element).html(html);
        
		});		
		$('.display').find('li#list').addClass('selected');
		$('.display').find('li#grid').removeAttr('class');
		$.totalStorage('display', 'list');
        
	}
	else 
	{
		$('ul.product_list.active').removeClass('list').addClass('grid row');
		$('.product_list.active > li').removeClass('col-xs-12').addClass('product-tile');
		$('.product_list.active > li').each(function(index, element) {
		html = '';
		html += '<div class="product-container">';
			html += '<div class="left-block">' + $(element).find('.left-block').html() + '</div>';
			html += '<div class="right-block">';
				html += '<h3 itemprop="name">'+ $(element).find('h3').html() + '</h3>';
                brand = $(element).find('.brand');
                    if (brand.length)
                        html += '<p class="brand">'+ brand.html() + '</p>';
                   
				var rating = $(element).find('.comments_note').html(); // check : rating
					if (rating !== null) { 
						html += '<div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating" class="comments_note">'+ rating + '</div>';
					}
				html += '<p itemprop="description" class="product-desc">'+ $(element).find('.product-desc').html() + '</p>';
				html += '<div class="product_link_more">'+ $(element).find('.product_link_more').html() + '</div>';
			var price = $(element).find('.content_price').html(); // check : catalog mode is enabled
					if (price !== null) { 
						html += '<div class="content_price">'+ price + '</div>';
					}
				html += '<div itemprop="offers" itemscope itemtype="http://schema.org/Offer" class="button-container">'+ $(element).find('.button-container').html() +'</div>';
				var colorList = $(element).find('.color-list-container').html();
				if (colorList !== null) {
					html += '<div class="color-list-container">'+ colorList +'</div>';
				}
				var availability = $(element).find('.availability').html(); // check : catalog mode is enabled
				if (availability !== null) {
					html += '<span class="availability">'+ availability +'</span>';
				}
			html += '</div>';
			html += '<div class="functional-buttons clearfix">' + $(element).find('.functional-buttons').html() + '</div>';
		html += '</div></div>';		
		$(element).html(html);
		});
		$('.display').find('li#grid').addClass('selected');
		$('.display').find('li#list').removeAttr('class');
		$.totalStorage('display', 'grid');
        
	}	
}

function dropDown() 
{
	elementClick = '#header .current';
	elementSlide =  'ul.toogle_content';       
	activeClass = 'active';			 

	$(elementClick).on('click', function(e){
		e.stopPropagation();
		var subUl = $(this).next(elementSlide);
		if(subUl.is(':hidden'))
		{
			subUl.slideDown();
			$(this).addClass(activeClass);	
		}
		else
		{
			subUl.slideUp();
			$(this).removeClass(activeClass);
		}
		$(elementClick).not(this).next(elementSlide).slideUp();
		$(elementClick).not(this).removeClass(activeClass);
		e.preventDefault();
	});

	$(elementSlide).on('click', function(e){
		e.stopPropagation();
	});

	$(document).on('click', function(e){
		e.stopPropagation();
		var elementHide = $(elementClick).next(elementSlide);
		$(elementHide).slideUp();
		$(elementClick).removeClass('active');
	});
}


function getErrorTxt(n){
    if ( n == 1){
        return txtError;
    } else if ( n < 5){
        return txtErrors; 
    } else {
        return txtMoreErrors; 
    }
}

