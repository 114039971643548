
 

var theme = {
    x : -1,  
    isOverlay: 0,
    asyncScripts: [
    ],
    init: function(){
        var me = theme;
        me.loadAsyncScripts();

        $(document).on('changeMobile',theme.changeMobile);
        respResize.init();
        
        scrollController = new ScrollMagic.Controller();
        $(document).trigger('scrollControllerInitialized');
        
        me.initHmMenu();
        me.initSearch();
        $('#page').append('<div class="loading-overlay"><div><div class="spinner"><i class="fa fa-spinner fa-spin"></i> '+loadingText+'...</div></div></div>');
        
        $(window).on('scroll',function(){
            if($(window).scrollTop()>50){
                $('#top-nav').addClass('_scroll');
                $('.shopping_cart').insertBefore('.header_user_info');
            } else {
                $('#top-nav').removeClass('_scroll');
                $('.shopping_cart').insertBefore('#search_block_top');
            }
        });
        $(document).trigger('themeInitialized');

    },
 
    initSearch: () => { 
        var searchData = [];

        $('#search-top-query').autoComplete({
            minChars: 3,
            source: function(term, suggest){
                $.getJSON(search_url+"?ajaxSearch=1&limit=10&id_lang="+id_lang, { q: term }, function(data){ 
                    searchData = data;
                    suggest(searchData); 
                });
            },
            renderItem: (item, search) => {
                search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
                var name = item.pname;
                return '<div class="autocomplete-suggestion" data-val="' + name + '" data-id="'+item.id_product+'">'+
                    name.replace(re, "<b>$1</b>") + '</div>';
            },
            onSelect: function(e, term, item){
                e.preventDefault();
                e.stopPropagation();
                var id = item.data('id');
                for(var i in searchData){
                    if (parseInt(searchData[i].id_product) === id){
                        location.replace(searchData[i].product_link);
                        return;
                    }
                }
            }
        });
    },
    
    initHmMenu: () => {
        
        var $hm = $('<div  id="hm-menu"><div class="menu-container"><button><span></span></button></div><div id="hm-overlay"></div></div>');
        var $menu = $('#categories_block_left ul.categories').clone(false)
                .removeClass('tree dhtml dynamized')
                .attr('id','hm-menu-menu');
        $('#top-nav > div > div').prepend($hm);
        $('#hm-menu > .menu-container').append($menu);
        $menu.find('ul').prev().before("<span class='grower'></span>");
        
        $('#hm-menu > .menu-container > button').click(function(){
            $(this).parent().parent().toggleClass('_open');
        });
        $('#hm-overlay').click(function(){
            $(this).parent().removeClass('_open');
        });
        $menu.find('.grower').click(theme.hMenuToggle);
        var $catbl = $('#categories-left');
        if ($catbl.parent().prop('id') === 'right-column'){ 
            $catbl.remove();
        }
    },
    
    showOverlay: function (){
        if ( !this.isOverlay ) 
            $('.loading-overlay').stop().fadeIn();
        $('.loading-overlay .spinner').css({'top':($(window).scrollTop() + $(window).height()/2)});
        this.isOverlay++;
    },
    
    hideOverlay: function (){
        this.isOverlay--;
        if ( this.isOverlay ) return;
        $('.loading-overlay').stop().fadeOut(200);  
    },

    hMenuToggle: function(){
        var $menu = $('#hm-menu-menu'), $li = $(this).parent();
        if($li.hasClass('_open')){
            $li.add($li.children('li')).removeClass('_open');
        } else {
            $menu.find('li._open').not($li.parents('li')).removeClass('_open');
            $li.addClass('_open');
        }
    },
    
    loadAsyncScripts: function(){
        var me = theme;
        var n = function(o,c) {
            var n = document.createElement('script'); n.type = 'text/javascript';
            n.async = true; n.src = o;
            c = c ? c : function(){ theme.scriptAsyncLoaded(o); };
            n.addEventListener ? n.onload = c:
            n.onreadystatechange = function(){/loaded|complete/.test(n.readyState)&&c();};
            return n;
        };
        if ( me.asyncScripts.length ) {
            while(me.asyncScripts[++me.x]) 
                document.getElementsByTagName("head")[0].appendChild(n(me.asyncScripts[me.x]));
        } else 
            $(document).trigger('allAsyncScriptsLoaded');
    },
    
    scriptAsyncLoaded: function(s){
        var me = theme;
        --me.x;
        $(document).trigger({type:'asyncScriptLoaded', script:s.split('/').pop()});
        if(me.x) 
            return;
        $(document).trigger('allAsyncScriptsLoaded');
    },
    
    onLoad: function(){
        var me = theme;
        me.loaded = true;
        $('body').addClass('loaded');
    },

    changeMobile: function(e){
        if(e.mobile){
            $('#manufacturers_block_left').appendTo('#right-column');
            $('#special_block_right').appendTo('#right-column');
            $('#viewed-products_block_left').appendTo('#right-column');
        } else {
            $('#manufacturers_block_left').appendTo('#left-column');
            $('#special_block_right').appendTo('#left-column');
            $('#viewed-products_block_left').appendTo('#left-column');
        }
    },
    
    getErrorTxt: function(n){
        if ( n == 1){
            return txtError;
        } else if ( n < 5){
            return txtErrors; 
        } else {
            return txtMoreErrors; 
        }
    }
};
