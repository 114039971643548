/**
*  respResize
*  
*  @ver 1.2.0
*/
// brXs: 576, < is Vs, > is Xs
// brSm: 768, > is Sm
// brMd: 992, > is Md
// brLg: 1200, > is Lg
// brXl: 1580, > is Xl

var respResize = {
    mobile: null,
    breakpoints: [
        { name: 'Vs', value: 576},
        { name: 'Xs', value: 768},
        { name: 'Sm', value: 992},
        { name: 'Md', value: 1200},
        { name: 'Lg', value: 1580},
        { name: 'Xl', value: 0}
    ],
    breakpoint : false,
    timer : null,
    test : null,
    init: function(){
        this.resize(true);
        $(window).resize(function(){
            var me = respResize;
            window.clearTimeout(me.timer);
            me.timer = setTimeout(me.resize,200);
        }); 
    },
    getWidth: function(){
        return $(window).width()+this.scrollCompensate();
    },
    
    resize: function(force){
        force = !!force;
        var me = respResize, br = 0, oldBr, mobile = false,
            w = me.getWidth();
        
        for( var i in me.breakpoints)
            if(me.breakpoints[i].value > w)
                break;
        
        if ( i <= 2 ) mobile = true;
        
        if( me.breakpoints[i].name !== me.breakpoint ){
            oldBr = me.breakpoint;
            me.breakpoint = me.breakpoints[i].name;
            $.event.trigger({type:'changeBreakpoint', mobile: mobile, width: w, oldBreakpoint: oldBr, breakpoint: me.breakpoint});
        }
        
        if ( force || me.mobile !== mobile ){
            me.mobile = mobile;
            if( mobile)
                $.event.trigger({type:'changeMobile', mobile:true, width: w, breakpoint: me.breakpoint});
            else 
                $.event.trigger({type:'changeMobile', mobile:false, width: w, breakpoint: me.breakpoint});
        }
        $.event.trigger({type:'resizeResponsive',mobile:me.mobile, width: w, breakpoint: me.breakpoint});
    },
    
    scrollCompensate: function ()
    {
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild(inner);

        document.body.appendChild(outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        if (w1 == w2) w2 = outer.clientWidth;

        document.body.removeChild(outer);

        return (w1 - w2); 
    }, 
    isMobile: function(){
        if ( this.mobile === null ){
            this.resize(true);
        }
        return this.mobile;
    }
}; 


//$(window).on('resizeResponsive',function(e){
//    console.log('resizeResponsive',e);
//});
//$(window).on('changeBreakpoint',function(e){
//    console.log('changeBreakpoint',e);
//});
//$(window).on('changeMobile',function(e){
//    console.log('changeMobile',e);
//});