//
// @version 1.0.2
//
 $.fn.productSlider = function(opt){
    var  o = {};
    if ( typeof opt === 'object') o = opt;
    
    var settings = $.extend({
        speed: 500,
        loop: true,
        autoplay: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerGroup: 1,
        slidesPerView: 4,
        breakpoints: {
            480: {
                slidesPerView: 1,
                slidesPerGroup: 1
            },
            768: {
              slidesPerView: 2
            },
            992: {
              slidesPerView: 3
            }
        }
    }, o );


    function resize(){
        var me = this, w = respResize.getWidth(), 
            maxSlides = settings.slidesPerView, 
            points, minPoints = false, intPoints,
            slider = me.data('slider');
        
        var il = me.find('li').not('.swiper-slide-duplicate').length;
        for( points in settings.breakpoints){ 
            intPoints = parseInt(points);
            if( intPoints >= w && ( intPoints > minPoints || minPoints === false)){
                minPoints = points;
            }
        }
        if(minPoints !== false)
            maxSlides = settings.breakpoints[minPoints].slidesPerView;
        
        if ( il <= maxSlides ){
            if ( !!slider ){
                slider.destroy();
                me.data('slider',null);
            }
            me.find('ul').removeClass('swiper-wrapper slider')
                    .addClass('grid')
                    .css({transform:''});
            me.addClass('_inactive').removeClass('_active');
            me.find('li').removeClass('swiper-slide')
                    .removeAttr("style");
        } else {
            me.find('ul').removeClass('grid').addClass('swiper-wrapper slider');
            me.find('li').addClass('swiper-slide');
            me.removeClass('_inactive').addClass('_active');
            if ( !!slider ){
            }else {
                slider = new Swiper(me,settings);
                me.data('slider',slider);
            }
        }
    }
    
    return this.each(function(){
        var me = $(this), tm,
            slider = me.data('slider');

        if ( !!slider || !Swiper) 
            return;
        
        if ( opt === 'destroy') {
            if ( !!slider){
                slider.destroy();
                return me;
            }
        } 
        
        $(window).resize(function(){
            clearTimeout(tm);
            tm = setTimeout(function(){
                resize.apply(me);
            },200);
        });
        resize.apply(me);
        return me;
    });
};
